// eslint-disable-next-line prefer-destructuring, @typescript-eslint/dot-notation
const env = window['env'];

const appConfig = {
    tenantApplicationClientId: env.TENANT_APPLICATION_CLIENT_ID,
    tenantName: env.TENANT_NAME,
    tenantDomain: `${env.TENANT_NAME}.onmicrosoft.com`,
    userFlowName: 'B2C_1A_TEN_NKP_UNIVERSAL_SIGN_IN_V1',
    services: {
        connectionRequestFormApi: '/connection-request-form-api',
        distributedGenerationApi: '/distributed-generation-api',
        contractApi: '/contract-api',
    },
    showDigCommissioningProcessForm: env.SHOW_DIG_COMMISSIONING_PROCESS_FORM === 'true',
    applicationInsightsConnectionString: env.APPLICATIONINSIGHTS_CONNECTION_STRING,
    enableFrontendAppInsightsErrorLogging: env.ENABLE_FRONTEND_APP_INSIGHTS_ERROR_LOGGING === 'true',
};

export default appConfig;
